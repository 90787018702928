import React from 'react';

import Suspense from '@arandu/laravel-mui-admin/lib/components/Suspense';
import { route } from '@arandu/laravel-mui-admin';

import Error from '../views/Error';
import { Navigate } from 'react-router-dom';

export default () => [
    {
        path: '/',
        element: (
            <Suspense>
                {React.lazy(() => import('../views/Layouts/Guest'))}
            </Suspense>
        ),
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: (
                    <Navigate to={route('login')} />
                ),
            },
            {
                path: route('login'),
                element: (
                    <Suspense>
                        {React.lazy(() => import('../views/Auth/Login'))}
                    </Suspense>
                ),
            },
            {
                path: route('password.request'),
                element: (
                    <Suspense>
                        {React.lazy(() => import('../views/Auth/Passwords/Email'))}
                    </Suspense>
                ),
            },
            {
                path: route('password.reset'),
                element: (
                    <Suspense>
                        {React.lazy(() => import('../views/Auth/Passwords/Reset'))}
                    </Suspense>
                ),
            },

        ],
    },
];
